import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { CommonHeading } from "../../common/commonHeading.js";
//import { cameraPlacements } from  '../hooks/_data'
import spinnerLoader from "../../assets/images/loader.svg";
import moment from "moment";
import {
  getLanguageTranslation
} from "../../dashboard/actionMethods/actionMethods.js";
import DashboardLanguage from "../../components/dashboardLanguage.js";
import CommonDatePicker from "../../common/commonDatePicker.js";

import { Slider} from 'antd';
import { Select } from 'antd';
import LocationIcon from  '../../assets/images/location.png'

import {getPproductivitylistDetail } from "../../productivity/actionMethods/actionMethods.js";
import {getActiveEmployee} from '../../employeeTracker/actionMethods/actionMethod.js';

import { getPproductivityDetailOverview,
  getIndividualProductivityList } from '../../productivity/actionMethods/actionMethods.js';

  import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import '../../employeeTracker/style/style.scss'

import '../../realtimeTracker/style/style.scss'
import Scrollbars from 'react-custom-scrollbars';
import 'antd/dist/antd.css';
import { useScript, useStyle } from './useScriptAndStyle.js';
import { getLocationList,getEmployeeTracker, getLocationCordinateOld } from '../actionMethods/actionMethods.js';
import Light from '../../assets/images/light.png';
import Street from '../../assets/images/street.png';
import Satellite from '../../assets/images/satellite.png';
import Outdoors from '../../assets/images/outdoor.png';
import Dark from '../../assets/images/dark.png';
import '../style/styles.scss';

import ManProductiveIcon from '../../assets/images/man.png'

import { getLocationCordinate } from '../actionMethods/actionMethods.js';
import { map } from 'd3';



let dataUniqueLocation=[];
let previousTag={};
let biDirectional=[];

let layer=[];
let dataPush=[];
const MapboxMovementTracker = (props) => {
  const { Option } = Select;
  

  const [locationCordinate,SetLocationCordinate]= useState([]);
  const [slug,SetSlug] =useState('');
  const [error,SetError]=useState('');
  
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
  date.setHours(0, 0, 0, 0);  
  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
//const slideTooltip=useRef()
  const slideTooltip = useRef(null);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const existingPolygons = useRef([]);
  const [selectedStyle, setSelectedStyle] = useState('mapbox://styles/mapbox/streets-v11'); // Default style
  const [locationList, SetLocationList] = useState([]);
  const [currentMode, setCurrentMode] = useState('Streets');
  const [currentModeImg, setCurrentModeImg] = useState(Street);
  const [userMovement,SetUserMovement] = useState([]);


  const [width, setWidth] = useState(0);  
  
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);
  const [department,SetDeparment] =useState([]);
    const [department_id,SetDepartmentID]=useState([]);
  
  const [sub_department,SetSubDeparment] =useState([]);
    const [sub_department_id,SetSubDepartmentID]=useState([]);
    
  const [employeeID,SetEmployeeID]=useState(props.match.params && props.match.params.tag?props.match.params.tag:'');
  const [tag_serial,SetTagSerial] = useState([])
  const [employeeAll,SetEmployeeAll]=useState([]);
  const [employeeDefault,SetEmployeeDefault]=useState([]);
  const [loaderEmp,SetLoaderEmp]=useState(false)
  const [start_time,SetStartTime]=useState(props.match.params && props.match.params.hour?props.match.params.hour:'');
  const [end_time,SetEndTime]=useState('');
  const [isloading,Setloading] =useState(false);
  
  const [autoPlay,SetAutoPlay] =useState(false);
  const [productiveLocation,SetProductiveLocation] =useState([])
  const [nonproductiveLocation,SetNonProductiveLocation] =useState([])
  const [lessproductiveLocation,SetLessProductiveLocation] =useState([])
    const [selectedEmployee,SetSelectedEmployee]=useState([])

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));

  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 0;
  let lastReqId = null
  
  const [shiftStarttime,SetShiftStartTime] =useState('');
  const[ ShiftEndtime,SetShiftEndTime]=useState('');
  const [shiftBasedDropdown,SetShiftBasedDropdown] =useState([]);  
  const [activeHourDropdown,SetActiveHourDropdown] = useState([]);
  
  const [filterTimeData,SetFilterTimeData] =useState([]);
  const [sucessMessage,SetSucessMessage] =useState(false);

  const [dailyEmpDefault,SetDailyEmpDefault] =useState([]);
  const [dailyEmpDepartment,SetDailyEmpDepartment] =useState([]);
  const [regularEmpDepartment,SetRegularEmpDepartment] =useState([]);
  const [enable,SetEnable] =useState(false);
  const [startBigger,SetStartBigger]=useState(false);  
  
  const elementRef =useRef(null);
  const [heightMap,SetHightMap]= useState(0);
  const [dualLocationList,SetDualLocationList]=useState([])
  const [oldPosition,SetOldPosition]=useState(0);
  const [lagFreeFlag,SetLagFreeFlag]=useState(false);
  const [selectedStartTime,SetSelectedStartTime]=useState([])
  const [tillTime,SetTillTime]= useState("");
  const [widthBox, SetWidthBox] = useState(60);
  const [locationListMapBox,SetLocationListMapBox] =  useState([]);
  const [loader,SetLoader]=useState(true);
  const markersRef = useRef([]);
  const activePopupRef = useRef(null);

  const timeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';    
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  
  const [workingHourDate,SetWorkingHourDate]= useState({
    start: null,
    end: null,
  })
  const [time,SetTime] = useState([])
  const [centroidMap,SetCentroidMap]= useState([]);
    let dummyCentroid= {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": []
    }
  }

  const employeeColors = [
  "hsl(327, 82.26567158506316%, 60.97715056422071%)",
  "hsl(85, 70.64022240311611%, 59.37694731059338%)",
  "hsl(232, 78.33173222789974%, 63.342422909838945%)",
  "hsl(169, 71.76858922889285%, 65.91014786444615%)",
  "hsl(202, 89.16741015481486%, 52.58000106855141%)",
  "hsl(242, 88.29193633248259%, 59.36498598958369%)",
  "hsl(272, 79.48908486330781%, 52.639054765121514%)",
  "hsl(274, 76.81813986875895%, 57.65624658447169%)",
  "hsl(235, 84.14697769635762%, 54.749638860839084%)",
  "hsl(4, 88.90590697258015%, 60.33761392079285%)",
  "hsl(161, 80.86552465656281%, 60.29653399413708%)",
  "hsl(232, 80.17406055088804%, 68.8517448960052%)",
  "hsl(95, 88.55826566824734%, 57.88550004721075%)",
  "hsl(249, 79.74947721076889%, 54.26868691142257%)",
  "hsl(303, 82.7050239149189%, 50.03175303957681%)",
  "hsl(34, 73.115918968355%, 67.80889138870546%)",
  "hsl(291, 77.25319382246062%, 62.803744767106664%)",
  "hsl(94, 77.96760062842331%, 52.92365617263922%)",
  "hsl(46, 84.8303613737061%, 64.42589664049896%)",
  "hsl(227, 76.44499478916535%, 55.616360256700496%)",
  "hsl(255, 83.49229912915347%, 66.93275038624265%)",
  "hsl(111, 83.97217415389542%, 58.75478342561874%)",
  "hsl(96, 70.21405694880971%, 52.295629219358595%)",
  "hsl(37, 81.06911565101312%, 55.15391753518382%)",
  "hsl(33, 70.5472889255923%, 57.30533718824168%)",
  "hsl(340, 71.64433277509757%, 64.93636217119655%)",
  "hsl(182, 77.48012688303484%, 54.503880694346506%)",
  "hsl(88, 80.02917454647314%, 59.5387893626463%)",
  "hsl(106, 81.0372036468741%, 53.53533106375215%)",
  "hsl(132, 77.43159339032577%, 66.42557204132112%)",
  "hsl(226, 81.19243499402423%, 57.1365324022334%)",
  "hsl(98, 76.86393424558578%, 65.26373647346868%)",
  "hsl(260, 88.75734060290213%, 59.569943810880005%)",
  "hsl(241, 81.26515771340036%, 57.333705671653824%)",
  "hsl(163, 88.77844139148317%, 62.17769936330274%)",
  "hsl(84, 86.25100655783098%, 50.91095183661988%)",
  "hsl(125, 73.14283236678713%, 58.82383974855697%)",
  "hsl(304, 89.46959860851348%, 57.840525541680265%)",
  "hsl(343, 83.89986453847341%, 67.39262242352639%)",
  "hsl(331, 82.38118593893205%, 55.80521849997554%)",
  "hsl(159, 73.36873752968378%, 57.821775602037036%)",
  "hsl(55, 74.75664796141574%, 63.980446951258685%)",
  "hsl(81, 89.53106911305613%, 57.494102492484814%)",
  "hsl(187, 85.93031853842255%, 61.130542906747706%)",
  "hsl(316, 80.04151787402822%, 63.11004945420853%)",
  "hsl(14, 73.26609079809602%, 62.713397384249674%)",
  "hsl(255, 77.17138706192031%, 53.33651254994941%)",
  "hsl(200, 84.50097687088532%, 51.15837135485338%)",
  "hsl(312, 89.55943890354735%, 68.17091179512873%)",
  "hsl(77, 81.47697520706821%, 50.63483487051467%)",
  "hsl(82, 74.10844896759514%, 52.74895453080439%)",
  "hsl(121, 80.74244915365531%, 56.20993334804485%)",
  "hsl(191, 81.60917444953981%, 57.939392437514286%)",
  "hsl(154, 81.32043377992234%, 56.27106634416671%)",
  "hsl(291, 72.71520762526647%, 53.13825922138794%)",
  "hsl(260, 87.57179824763554%, 63.741165713545925%)",
  "hsl(337, 82.29031316032561%, 51.63541052528449%)",
  "hsl(135, 73.12031818797993%, 64.1628770989625%)",
  "hsl(268, 86.48706783007081%, 65.60086631269219%)",
  "hsl(157, 70.718742429204%, 69.28321303853468%)",
  "hsl(134, 77.62285272334925%, 57.27929507257956%)",
  "hsl(199, 87.98029074554799%, 63.957756657077425%)",
  "hsl(123, 87.15465938042696%, 51.44207395446548%)",
  "hsl(218, 83.72905181607035%, 54.55279252839061%)",
  "hsl(129, 85.38453145677208%, 67.07751799183053%)",
  "hsl(52, 74.3875600264342%, 62.05636871408277%)",
  "hsl(110, 75.6056018481851%, 55.74111855744189%)",
  "hsl(240, 77.26460095845422%, 59.21841933367131%)",
  "hsl(206, 88.14692883136689%, 67.61928628463536%)",
  "hsl(27, 82.1440105142751%, 66.77397319491112%)",
  "hsl(152, 87.21033092698117%, 63.95217377500005%)",
  "hsl(319, 77.83902351391065%, 63.65137732622294%)",
  "hsl(21, 80.62906408463238%, 65.37873945536234%)",
  "hsl(302, 88.22826515887856%, 65.80359877558654%)",
  "hsl(156, 88.00795439776368%, 54.42808683792161%)",
  "hsl(290, 87.20538515621656%, 52.175846435681024%)",
  "hsl(230, 88.37497990517323%, 55.689179390697454%)",
  "hsl(47, 81.40009846363385%, 65.63140395208232%)",
  "hsl(285, 70.70633802504527%, 63.64842466005975%)",
  "hsl(271, 78.04927715973233%, 59.76228748911467%)",
  "hsl(219, 70.4317040860351%, 67.91820268818334%)",
  "hsl(116, 70.931240710324%, 60.37919414710038%)",
  "hsl(299, 87.44567086905666%, 64.61569784088458%)",
  "hsl(246, 89.39357314780656%, 52.95499877887383%)",
  "hsl(113, 74.27150089174917%, 56.43205765692632%)",
  "hsl(25, 74.44494196695213%, 56.706176997900755%)",
  "hsl(325, 71.36865675175873%, 52.397634810209205%)",
  "hsl(111, 72.4738541221483%, 50.20750017213243%)",
  "hsl(96, 77.87790986300581%, 50.24805118814144%)",
  "hsl(266, 70.4923351924882%, 62.944091473873584%)",
  "hsl(3, 87.77934607638794%, 62.85500800399073%)",
  "hsl(181, 83.93207447022874%, 66.34151371706442%)",
  "hsl(253, 83.08591122696579%, 50.95173464951785%)",
  "hsl(137, 86.03711520793748%, 61.609108712636825%)",
  "hsl(148, 84.1206438147863%, 66.99831700077246%)",
  "hsl(330, 89.29766701835794%, 57.99885120627299%)",
  "hsl(104, 89.9528757261081%, 53.716414747069216%)",
  "hsl(53, 82.81577499109058%, 61.15279922108968%)",
  "hsl(191, 70.21547473535884%, 65.20538415961737%)",
  "hsl(214, 74.31459136717498%, 53.57145835280156%)"
];
  
useEffect(()=>{
  var dateObj = moment(selectedDate); 
  let startHour = props.workday.start_hour.split(":");
  var timeAndDate = moment(date).set({"hour": parseInt(startHour[0]), "minute": parseInt(startHour[0])});
  console.log("selectedDate date",date,startHour,timeAndDate,props.workday.end_hour,props.workday.end_hour.split(":")[0])
  let dt={start:null,end:null}

  if(timeAndDate <= moment() ){
    console.log("condition is here 1",parseInt(props.workday.start_hour.split(":")[0]));
    
    SetWorkingHourDate((prev) => ({ ...prev, [`start`]:moment(dateObj).set({"hour": parseInt(props.workday.start_hour.split(":")[0]) , "minute": parseInt(props.workday.start_hour.split(":")[1]) || 0})   }));
    SetWorkingHourDate((prev) => ({ ...prev, [`end`]:  moment(dateObj).add(addDay,'days').set({"hour": parseInt(props.workday.end_hour.split(":")[0]) , "minute": parseInt(props.workday.end_hour.split(":")[1]) || 0}) }));
  }else {   
    console.log("condition is here 2");
    SetWorkingHourDate((prev) => ({ ...prev, [`start`]:moment(dateObj).subtract(addDay,'days').set({"hour": parseInt(props.workday.start_hour.split(":")[0]), "minute": parseInt(props.workday.start_hour.split(":")[1]) || 0})   }));
    SetWorkingHourDate((prev) => ({ ...prev, [`end`]:  dateObj.set({"hour": parseInt(props.workday.end_hour.split(":")[0]), "minute": parseInt(props.workday.end_hour.split(":")[1]) || 0}) }));
  }
  
 
},[selectedDate,addDay])

useEffect(()=>{

// Parse the start and end times into Date objects
let start = new Date(workingHourDate.start);
let end = new Date(workingHourDate.end);

// Array to hold the time slots
let timeSlots = [];

// Loop from start time to end time, incrementing each hour
while (start <= end) {
    let formattedDate = start.toLocaleDateString("en-GB", { day: "2-digit", month: "short" }); // Format as "DD MMM"
    let formattedTime = start.toLocaleString("en-US", { hour: "numeric", hour12: true }); // Format as "07 AM"

    timeSlots.push({
        time: `${formattedDate} ${formattedTime}`,
        value: start.toTimeString().slice(0, 8), // Format as "HH:MM:SS"
        intVal: start.getHours(),
        currentTime: new Date(start), // Set to the same hour and minute
    });

    // Increment the time by one hour
    start.setHours(start.getHours() + 1);
}

console.log(timeSlots);
SetTime(timeSlots);
},[workingHourDate])

  const mapboxtoken = process.env.REACT_APP_MAPBOX_TOKEN

    const changeMapStyle = (newStyle, title, image) => {
        setSelectedStyle(newStyle);
        setCurrentMode(title);
        setCurrentModeImg(image);
        setTimeout(() => SetWidthBox(60), 500);

        if (mapRef.current) {
            mapRef.current.setStyle(newStyle);
        }
    };
    
    const mapBoxMode = [
        { function: 'mapbox://styles/mapbox/streets-v11', title: "Streets", image: Street },
        { function: 'mapbox://styles/mapbox/outdoors-v11', title: "Outdoors", image: Outdoors },
        { function: 'mapbox://styles/mapbox/light-v10', title: "Light", image: Light },
        { function: 'mapbox://styles/mapbox/dark-v10', title: "Dark", image: Dark },
        { function: 'mapbox://styles/mapbox/satellite-v9', title: "Satellite", image: Satellite }
    ];

    useScript('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.js');
    useStyle('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.css');
    useScript('https://cdnjs.cloudflare.com/ajax/libs/Turf.js/6.5.0/turf.min.js');

    useEffect(() => {
             if (window.mapboxgl && !loader && mapContainerRef.current && centroidMap.length != 0) {
        // if (window.mapboxgl && !loader) {
            window.mapboxgl.accessToken = mapboxtoken;

            // Initialize the map
            mapRef.current = new window.mapboxgl.Map({
                container: mapContainerRef.current,
                style: selectedStyle,
                center: centroidMap || [100.58317249183762, 13.72696169281258], // Example coordinates
                zoom: 19,
                projection: 'globe',
                pitch: 10,
                bearing: -15.6,
            });

            const nav = new window.mapboxgl.NavigationControl();
            mapRef.current.addControl(nav, 'top-right');
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, [selectedStyle, loader, locationList, locationCordinate,centroidMap]);

  const getMapCenterUsingTurf = (locations) => {
  // Convert locations to GeoJSON FeatureCollection
  console.log("locations",locations)
  const features = locations.map(loc => 
    window.turf.point([parseFloat(loc.position.x), parseFloat(loc.position.z)])
  );

  const featureCollection = window.turf.featureCollection(features);

  // Compute the center using Turf.js
  const center = window.turf.center(featureCollection); // or use turf.centerMean(featureCollection)
  console.log("center",center);
  return {
    lat: center.geometry.coordinates[1], 
    lng: center.geometry.coordinates[0]
  };
};


  useEffect(() => {
    const checkTurfLoaded = setInterval(async () => {
        if (window && window.turf) {
            clearInterval(checkTurfLoaded);

            try {
                const requestBody = {
                    session: userDetails.session,
                    org_id: userDetails.org_id,
                };
                

                const res = await getLocationList(requestBody);

                const res4 = await getLocationCordinateOld(requestBody);

                const olldLocation = res4?.data?.location || []
                console.log("res4",res4, olldLocation);
                if (res.status === 200 || res.code === 200) {
                    SetLocationListMapBox(res.data);
                    try {
                        const res3 = await getLocationCordinate(requestBody);
                        let ctMap = getMapCenterUsingTurf(olldLocation)
                        console.log("ctMap",ctMap,ctMap.lng,ctMap.lat)
                        SetCentroidMap([ctMap.lng,ctMap.lat])
                          // let centroidMap = window.turf.polygon(res3.data.map(el=>[el.coordinates]));
                          

                        if (res3.status === 200) {
                            const locList = res3.data.map(el => {
                                const polygonFeature = window.turf.polygon([el.coordinates]);
                                let centroid={}
                                let currentLoc = olldLocation.find(elm=>elm.tag_serial === el.location_serial);

                                if(currentLoc){
                                  let ct = {...dummyCentroid};
                                  ct.geometry.coordinates=[currentLoc.x,currentLoc.z]
                                 centroid = ct;
                                }else{
                                  centroid = window.turf.centroid(polygonFeature);  
                                }
                                


                                const allDetail = res.data.find(elm => elm.tag_serial === el.location_serial);
                                
                                console.log("Centroid", allDetail, centroid);

                                return { ...el, centroid, ...allDetail };
                            });

                            SetLocationCordinate(locList);
                            setTimeout(() => SetLoader(false), 200);
                            Setloading(false);
                        }
                    } catch (error) {
                        console.error("Error fetching location coordinates:", error);
                    }

                }
            } catch (error) {
                console.error("Error fetching location list:", error);
            }
        }
    }, 100);

    return () => clearInterval(checkTurfLoaded);
}, []);
    
useEffect(() => {

        if (locationCordinate.length > 0 && window.mapboxgl && mapRef.current && !loader) {
            locationCordinate.forEach(location => {
                const coordinates = location.coordinates;
                
                let loc = locationListMapBox.find(el => el.tag_serial === location.location_serial);


                mapRef.current.on('style.load', () => {
                    existingPolygons.current.push([coordinates]);
                    mapRef.current.addLayer({
                        id: `outline-${loc.id}`,
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: [coordinates]
                                },
                            },
                        },
                        layout: {},
                        paint: {
                            'line-color': '#4ec274',
                            'line-width': 1
                        }
                    });
                    addPolygonWithLabel([coordinates], loc.location_name, loc.id, location.centroid);
                });
            });

            
        }
        
    }, [locationCordinate, loader, locationListMapBox,selectedStyle]);
    
    function addPolygonWithLabel(polygonCoordinates, labelText, locationId, centroid) {
        const lngLat = centroid.geometry.coordinates;
        // new window.mapboxgl.Marker({ color: 'red' }).setLngLat(lngLat).addTo(mapRef.current);
        mapRef.current.addSource(`label-${locationId}`, {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: centroid.geometry.coordinates,
                },
                properties: {
                    title: labelText,
                },
            },
        });

        mapRef.current.addLayer({
            id: `label-layer-${locationId}`,
            type: 'symbol',
            source: `label-${locationId}`,
            layout: {
                'text-field': ['get', 'title'],
                'text-size': 10,
                'text-offset': [0, 0.5],
                'text-anchor': 'top',
            },
            paint: {
                'text-color': '#000000',
            },
        });
    }


  useEffect(() => {
    
    if(elementRef && elementRef.current){
     
      SetHightMap(elementRef.current.getBoundingClientRect().height);
      setWidth(elementRef.current.offsetWidth);
    }
    
    
  }, [elementRef,isloading]);

useEffect(()=>{
if(props.match.params && props.match.params.tag ){
  let useDaily= dailyEmpDefault.find(item=>item.tag_serial==props.match.params.tag);  
  if(useDaily){
    SetSlug('daily_worker')
    SetDepartmentID(useDaily.department)
  }else{
    let useRegular = employeeAll.find(item=>item.tag_serial==props.match.params.tag);  
    SetSlug('employee');
    if(useRegular){
      SetDepartmentID(useRegular.department)
    }
  }
}
},[props.match.params,employeeAll,dailyEmpDefault])

useEffect(() => {
    setTimeout(function(){
      SetLagFreeFlag(true);
    },3000)
  }, []);

  useEffect(() => {
   
    let perm= props.pages.filter(el=>el.sub_page_name && el.sub_page_name =="Movement Analyzer Mapbox");
    console.log("perm",perm)
   
    if(perm){
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
    }
},[props.pages]);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}


useEffect(()=>{
  if(permission.length > 0){
    let sub=[];  
    let subDept=[];
    SetSubDeparment([]);
    //SetEmployeeID('')
    if(props.match.params.hour == start_time &&  props.match.params.tag==employeeID){

    }else{
      SetEmployeeID([])
      SetTagSerial([])
      SetSelectedEmployee([])
    }
    SetSubDepartmentID([])

    let sub2= department.filter(item=>department_id.indexOf(item.department) > -1 )
    

    let subCat=[]
    if(sub2.length> 0){

      for(let i=0;i<sub2.length;i++){
        subCat.push(...sub2[i].sub_department)
      }
      SetSubDeparment([...new Set(subCat)]);  
    }    

  }
},[permission,department_id])

useEffect(()=>{
  if(permission.length > 0){
    let sub=[];  
    let subDept=[];
    //SetEmployeeID('')
    if(props.match.params.hour == start_time & props.match.params.tag==employeeID){

    }else{
      SetEmployeeID([])
      SetTagSerial([])
      SetSelectedEmployee([])
    }

  }
},[permission,sub_department_id])


    function fetchAllHour(start,end,data){
        const startDate = start;
        const endDate = end; // Assuming end_time is on the next day

        const hourArray = [];
        Object.keys(data).forEach(element => {
            Object.keys(data[element]).forEach(key2=>{
                hourArray.push({hour:parseInt(key2).toString() ,date:element,sortTime:element+" "+key2+":00:00"});
            });        
        });
        let sortedTIme = hourArray.sort((a,b)=>new Date(a.sortTime) - new Date(b.sortTime))
       

        return sortedTIme;

    }


function removeLayerSmplrSpace(){
  
    
    }



  function handleDateSelect(date) {
    updateSelectedDate(date);
    SetSlug('');
    SetStartTime([]);

      removeLayerSmplrSpace()    
             setCount(0);
           SetSucessMessage(false);
           SetLoaderEmp(false);
       SetDefaultPoly([])
    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function AddDataLayer(data){
}

function convertTimeZone(time){
 const utcTimeString = time;
const utcDateTime = new Date(utcTimeString + "Z"); // Append 'Z' to indicate UTC time
const indonesiaTimeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';  
const indonesiaOptions = { timeZone:  indonesiaTimeZone };
const indonesiaTimeString = utcDateTime.toLocaleString("en-US", indonesiaOptions);
return moment(indonesiaTimeString).format('hh:mm:ss A')

} 

  function updateDataLayer(data,url,width,widthSize,inc){

  }

  function filterResultLocation(name){

  }
  

  
  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }
    
  const  [marks,SetMark]  = useState([]) 
  const [marks2,SetMark2] =useState([]) 
  const [defaultPoly,SetDefaultPoly] = useState([])
  const [currentCount, setCount] = useState(0);
  const randomcolor = ['#000','#d95780','#51ac30','#101535','#b8cdda','#ffa500','#ff0000']



  const timer = () => {
    setCount(currentCount + 1);    
  }

      function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
        
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    
  
  
    const handleDate = (date, type) => {
      // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }


    function HandleRange(e,poly){
      console.log("HandleRange e",e,poly[e],marks[e - 1]);
      if(poly[e -1] && poly[e -1].time){
        SetTillTime(poly[e - 1].time)
      }else{
        SetTillTime(poly[0].time)
      }
      

      setCount(e);
    }

   function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  
async function filterResultHandler(tag = employeeID, stime = start_time, bigTimer = startBigger) {
// async function filterResultHandler(tag = , stime = '2024-11-06 14:00:00', bigTimer = startBigger) {
  let dataUniqueLocation = [];
  let dualLocation = [];
  let arr = [];
  let mk = [];
  let locationPoint = [...locationCordinate,{
  "coordinates": [],
  "id": 'Out of Boundary',
  "location_serial": "Out of Boundary",
  "org_id": 36,
  "properties": {},
  "centroid": {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": []
    }
  },
  "area_size": 5,
  "capacity": 5,
  "categories": "Outdoor",
  "description": "Out of Boundary",
  "loc_type": 1,
  "location_name": "Out of Boundary",
  "tag_serial": "Out of Boundary"
},{
  "coordinates": [],
"id": 'Out of Range',
  "location_serial": "Out of Range",
  "org_id": 36,
  "properties": {},
  "centroid": {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": []
    }
  },
  "area_size": 5,
  "capacity": 5,
  "categories": "Outdoor",
  "description": "Out of Range",
  "loc_type": 1,
  "location_name": "Out of Range",
  "tag_serial": "Out of Range"
}];

  
  SetLoaderEmp(true);
  setCount(0);
  SetError('');
  SetFilterTimeData([]);
  SetSucessMessage(false);
  console.log("tag",tag)
  console.log("bigTimer",bigTimer)
  // const stime = stimeInput.map(el => activeHourDropdown.find(item => item.hour === el));

  let requestBody={}
    requestBody.start_time = moment(start_time).format("HH")+":00:00"; 
    requestBody.end_time =moment(start_time).add(1, 'hour').format("HH")+":00:00";
    requestBody.date= getDateFormat(start_time);
    requestBody.worker_id= tag.join(',')
    requestBody.worker_id= tag.join(',')
    requestBody.work_day= moment(selectedDate).format('YYYY-MM-DD')
  
    

    try {
      const res = await getEmployeeTracker(requestBody, userSession, org_id, sub_org_id);
      console.log("res",res);
      if (res.status === 200) 
        {
        tag_serial.forEach((tagID,i)=>{
          
      let data = res.data[tagID] ? res.data[tagID] : [];

        console.log("tag",tag_serial,tagID,data)
        if (data.length > 0) {
          console.log("in if",data);
          data = data.sort((a, b) => moment(a.timestamp) - moment(b.timestamp));
        }else{
console.log("in else",data);
        }


        let previousLocation = [];
        let incCount = 0;
        let empName=employeeDefault.find(item=>item.tag_serial==tagID);
        
         data.forEach((entry) => {

          const key = entry.location_serial;
          const findPos = locationPoint.find(item => item.location_serial === key);
          let locName='';
          if (findPos) {
            mk.push(moment.utc(entry.timestamp));
            // moment.utc(item.time_spent).format("HH:mm:ss")
            if (!dataUniqueLocation.includes(findPos.location_name)) {
              dataUniqueLocation.push(findPos.location_name	);
            }
            const polylineFormat = {
              levelIndex: 0,
              x: entry.location[0],
              z: entry.location[1],
              elevation: 5,
              name: findPos.location_name	,
              tag_serial: findPos.location_serial,
              employee_tag:tagID,
              time: entry.timestamp,
              emp_name:empName?.name,
              position: incCount,
              worker_id:tag[i]
            };
            arr.push(polylineFormat);
          if(locName===entry.location_serial){
          
          }else{
            console.log("previousLocation",previousLocation);
            previousLocation.push(polylineFormat);
            if (previousLocation.length > 1) {
              const oldLoc = previousLocation[previousLocation.length - 1];
              const newLoc = polylineFormat;

              dualLocation.push({
                old: { ...oldLoc },
                new: { ...newLoc },
                position: incCount,
                employee_tag:tagID,
                worker_id:tag[i]
              });
              console.log("dualLocation",dualLocation,previousLocation)
            }
            locName=entry.location_serial;
          }
          

            
  
            incCount++;
          }
        });

        })

        
        

      
      }
    } catch (error) {
      console.error("Error in employee tracker request:", error);
    }
  
    

  // Wait for all promises to complete
  

  // After all async operations, update the state with collected data
  arr = arr.sort((a, b) => new Date(a.time) - new Date(b.time));
  mk = mk.sort((a, b) => new Date(a) - new Date(b)).map(item => moment(item).utc().format('hh:mm:ss A'));


let movement = arr.map(el=>{
  return ({location:[el.x,el.z],timestamp:el.time,name:el.name,tag_serial:el.tag_serial,employee_tag:el.employee_tag})
})
console.log("this is my data",arr,mk,movement)

if(arr.length > 0 ){
  SetTillTime(arr[arr.length - 1].time);
}

SetUserMovement(movement)


  SetDefaultPoly(arr);
  SetDualLocationList(dualLocation);
  SetSucessMessage(true);
  SetOldPosition(0);
  setCount(arr.length);
  SetMark(mk);
  SetLoaderEmp(false);
  SetSelectedStartTime(stime);

    let empWithPoly=[]
    // console.log()
    console.log("dualLocation empWithPoly tag_serial list",tag_serial)
    for(let i=0;i<tag_serial.length;i++){
      console.log("dualLocation empWithPoly",dualLocation,tag_serial[i],dualLocation.filter(item=>item.employee_tag==tag_serial[i]))
      if(dualLocation.filter(item=>item.employee_tag==tag_serial[i]).length>0){
        empWithPoly.push(tag_serial[i])
      }  
    }
   console.log("empWithPoly",empWithPoly);
    SetSelectedEmployee(empWithPoly)

}


// async function getMatch(coordinates, radius, profile, dataToShow, timeData, employeeID) {

//  const lineCoordinates = []; // To store coordinates for the path line
 
// dataToShow.forEach((movement, index) => {
//   console.log("movement",movement);
//       const { location, timestamp } = movement;

//             let length = dataToShow.filter(elm=>elm.tag_serial  === movement.tag_serial).length;
//             const el = document.createElement('div');
           
//             const width = 15;
//             const height =25;
//             el.className = 'marker';
//             el.style.backgroundImage = `url(${ManProductiveIcon})`;
//             el.style.width = `${width}px`;
//             el.style.height = `${height}px`;
//             el.style.backgroundSize = '100%';
//             // new window.mapboxgl.Marker(el).setLngLat(point).addTo(mapRef.current);
//       let tooltipText= `${movement.name}`;

//       if(timeData[index]!== undefined){
        
//         tooltipText+=` (${convertTimeZone(timeData[index].time)})`
//       }

//         const popup = new window.mapboxgl.Popup({
//             closeButton: false,
//             closeOnClick: false
//         }).setText(`${tooltipText})`);

//         const marker = new window.mapboxgl.Marker(el)
//             .setLngLat(location)
//             .setPopup(new window.mapboxgl.Popup().setText(`${movement.name}`)) // Optional popup
//             .addTo(mapRef.current);
//         marker.getElement().addEventListener('mouseenter', () => {
//             popup.setLngLat(location).addTo(mapRef.current);
//             activePopupRef.current = popup;
//         });
//         marker.getElement().addEventListener('mouseleave', () => {
//             popup.remove();
//         });     
//         markersRef.current.push(marker);


      
//       lineCoordinates.push(location);
      

//       // Draw the line incrementally
//       if (index > -1 && lineCoordinates.length > 1) {
//         // Remove any existing line
//         if (mapRef.current.getSource('route')) {
//           mapRef.current.removeLayer('route');
//           mapRef.current.removeSource('route');
//         }

//         // Add GeoJSON line source
//         mapRef.current.addSource('route', {
//           type: 'geojson',
//           data: {
//             type: 'Feature',
//             geometry: {
//               type: 'LineString',
//               coordinates: lineCoordinates,
//             },
//           },
//         });

//         // Add line layer
//         mapRef.current.addLayer({
//           id: 'route',
//           type: 'line',
//           source: 'route',
//           layout: {
//             'line-join': 'round',
//             'line-cap': 'round',
//           },
//           paint: {
//             'line-color': '#888',
//             'line-width': 4,
//           },
//         });
//       }
//     });

//     const chunkSize = 60; // Define the size of each chunk
  
//     let allLineCoordinates = []; // 
//     const chunkArray = (arr, size) => {
//       const result = [];
//       for (let i = 0; i < arr.length; i += size) {
//         result.push(arr.slice(i, i + size));
//       }
//       return result;
//     };
//     const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
//     const coordinateChunks = chunkArray(coordinates, chunkSize);
//     const radiusChunks = chunkArray(radius, chunkSize);

//      for (let i = 0; i < coordinateChunks.length; i++) {
//     const coords = coordinateChunks[i].join(';');
//     const radiuses = radiusChunks[i].join(';');
//     const url = `https://api.mapbox.com/matching/v5/mapbox/${profile}/${coords}?geometries=geojson&radiuses=${radiuses}&steps=true&access_token=${mapboxtoken}`;

//     try {
//       // Fetch map-matching data
//       const response = await fetch(url, { method: 'GET' });
//       const data = await response.json();

//       // Check if response is successful
//       if (data.code === 'Ok') {
//         const coords = data.matchings[0].geometry.coordinates;
//         allLineCoordinates.push(...coords); // Aggregate coordinates for the final route
//       } else {
//         console.warn(`Map-matching error for chunk ${i + 1}: ${data.message}`);
//       }
//     } catch (error) {
//       console.error(`Error fetching map-matching data for chunk ${i + 1}:`, error);
//       // Skip this chunk and proceed to the next
//     }

//     // Delay to avoid hitting rate limits
//     await delay(200); // Adjust delay time as needed (200ms is an example)
//   }


 
// if (allLineCoordinates.length > 0) {
//   console.log("allLineCoordinates",allLineCoordinates)
//       addRoute(allLineCoordinates);
// }

      
      
//       }

async function getMatch(coordinates, radius, profile, dataToShow, timeData, employeeID) {
  const lineCoordinatesByEmployee = {}; // Store coordinates by employee ID
  const colors = {}; // Store colors for each employee
   // Define a list of colors for employees

  // Assign each employeeID a color from the employeeColors array
  employeeID.forEach((id, index) => {
    colors[id] = employeeColors[index % employeeColors.length];
    lineCoordinatesByEmployee[id] = [];
  });

  dataToShow.forEach((movement, index) => {
    const { location, timestamp, employee_tag } = movement; // Use employee_tag instead of tag_serial

    // Check if the current movement's employee_tag is in employeeID
    if (!employeeID.includes(employee_tag)) return;

    // Get the color for this employee and prepare a custom marker
    const employeeColor = colors[employee_tag];
    const el = document.createElement('div');
    const width = 15;
    const height = 25;
    el.className = 'marker';
    // el.style.backgroundColor = employeeColor; // Set background color
    el.style.backgroundImage = `url(${ManProductiveIcon})`;
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.backgroundSize = '100%';

    // Set up the tooltip with the movement name and optional timestamp
    let tooltipText = `${movement.name}`;
    if (timeData[index] !== undefined) {
      tooltipText += ` (${convertTimeZone(timeData[index].time)})`;
    }

    const popup = new window.mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    }).setText(`${tooltipText}`);

    const marker = new window.mapboxgl.Marker(el)
      .setLngLat(location)
      .setPopup(popup) // Optional popup
      .addTo(mapRef.current);
    
    // Attach popup events
    marker.getElement().addEventListener('mouseenter', () => {
      popup.setLngLat(location).addTo(mapRef.current);
      activePopupRef.current = popup;
    });
    marker.getElement().addEventListener('mouseleave', () => {
      popup.remove();
    });
    markersRef.current.push(marker);

    // Collect coordinates per employee for line plotting
    lineCoordinatesByEmployee[employee_tag].push(location);


    
  });

  // Draw lines for each employee in their assigned color
  for (let [id, coordinates] of Object.entries(lineCoordinatesByEmployee)) {
    console.log("id",id);
    if (coordinates.length > 1) {
      // Add GeoJSON source for the line
      mapRef.current.addSource(`route-${id}`, {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coordinates,
          },
        },
      });

      // Add line layer with the employee-specific color
      mapRef.current.addLayer({
        id: `route-${id}`,
        type: 'line',
        source: `route-${id}`,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': colors[id],
          'line-width': 4,
        },
      });

const chunkSize = 60; // Define the size of each chunk
  let allLineCoordinates = [];
  const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const coordinateChunks = chunkArray(coordinates, chunkSize);
  const radiusChunks = chunkArray(radius, chunkSize);

  for (let i = 0; i < coordinateChunks.length; i++) {
    const coords = coordinateChunks[i].join(';');
    const radiuses = radiusChunks[i].join(';');
    const url = `https://api.mapbox.com/matching/v5/mapbox/${profile}/${coords}?geometries=geojson&radiuses=${radiuses}&steps=true&access_token=${mapboxtoken}`;

    try {
      // Fetch map-matching data
      const response = await fetch(url, { method: 'GET' });
      const data = await response.json();

      // Check if response is successful
      if (data.code === 'Ok') {
        const coords = data.matchings[0].geometry.coordinates;
        allLineCoordinates.push(...coords); // Aggregate coordinates for the final route
      } else {
        console.warn(`Map-matching error for chunk ${i + 1}: ${data.message}`);
      }
    } catch (error) {
      console.error(`Error fetching map-matching data for chunk ${i + 1}:`, error);
      // Skip this chunk and proceed to the next
    }

    // Delay to avoid hitting rate limits
    await delay(200); // Adjust delay time as needed (200ms is an example)
  }

  if (allLineCoordinates.length > 0) {
    console.log("allLineCoordinates", allLineCoordinates);
    addRoute(allLineCoordinates,id,colors[id]);
  }

    }
  }

  // Perform map-matching as in the original code
  
}


    function updateRoute(coords,dataToShow,timeData,selectedEmployee) {
      removeRoute(); 
      const profile = 'walking'; // Set the profile
      const newCoords = coords.join(';');
      const radius = coords.map(() => 5);
      getMatch(coords, radius, profile,dataToShow,timeData,selectedEmployee);
    }


    // If the user clicks the delete draw button, remove the layer if it exists
//     function addRoute(coords) {
//   // Ensure `coords` is an array of coordinate pairs
//   if (!Array.isArray(coords) || coords.length === 0) {
//     console.error("Invalid coordinates provided to addRoute");
//     return;
//   }

//   // Format the data as a GeoJSON Feature object
//   const geojsonData = {
//     type: 'Feature',
//     properties: {},
//     geometry: {
//       type: 'LineString',
//       coordinates: coords,
//     },
//   };

//   // If a route is already loaded, remove it
//   if (mapRef.current.getSource('routematch')) {
//     mapRef.current.removeLayer('routematch');
//     mapRef.current.removeSource('routematch');
//   }

//   // Add a new source and layer for the route
//   mapRef.current.addSource('routematch', {
//     type: 'geojson',
//     data: geojsonData,
//   });

//   mapRef.current.addLayer({
//     id: 'routematch',
//     type: 'line',
//     source: 'routematch',
//     layout: {
//       'line-join': 'round',
//       'line-cap': 'round',
//     },
//     paint: {
//       'line-color': '#03AA46',
//       'line-width': 8,
//       'line-opacity': 0.8,
//     },
//   });
// }

function addRoute(coords, id, color) {
  // Ensure `coords` is an array of coordinate pairs
  if (!Array.isArray(coords) || coords.length === 0 || !Array.isArray(coords[0]) || coords[0].length !== 2) {
    console.error("Invalid coordinates provided to addRoute:", coords);
    return;
  }

  console.log("addRoute params:", coords, id, color);

  // Format the data as a GeoJSON Feature object
  const geojsonData = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: coords,
    },
  };

  // If a route is already loaded, remove it
  const sourceId = `routematch${id}`;
  if (mapRef.current.getSource(sourceId)) {
    mapRef.current.removeLayer(sourceId);
    mapRef.current.removeSource(sourceId);
  }

  // Add a new source and layer for the route
  mapRef.current.addSource(sourceId, {
    type: 'geojson',
    data: geojsonData,
  });

  mapRef.current.addLayer({
    id: sourceId,
    type: 'line',
    source: sourceId,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': color,
      'line-width': 8,
      'line-opacity': 0.8,
    },
  });
}


    function removeRoute() {
      if (!mapRef.current.getSource('routematch')) return;
          mapRef.current.removeLayer('routematch');
          mapRef.current.removeSource('routematch');
      }

useEffect(() => {
  if (!loader && userMovement.length > 0 && mapRef && mapRef.current && defaultPoly.length > 0) {
    
    const dataToShow = userMovement.slice(0, currentCount).filter(el=>selectedEmployee.indexOf(el.employee_tag) >-1)
    const timeData = defaultPoly.slice(0,currentCount ).filter(el=>selectedEmployee.indexOf(el.employee_tag) >-1);
    
    console.log("timeData",dataToShow,dataToShow)
    console.log("timeData timeData",timeData,selectedEmployee,timeData)
    console.log("FIltertimeData",tillTime,timeData,dataToShow);


    SetFilterTimeData(timeData)
  
    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];
        if (mapRef.current.getSource('route')) {
          mapRef.current.removeLayer('route');
          mapRef.current.removeSource('route');
        }
        if (mapRef.current.getSource('routematch')) {
          mapRef.current.removeLayer('routematch');
          mapRef.current.removeSource('routematch');
        }
        for(let i=0;i<employeeAll.length;i++){
           if (mapRef.current.getSource(`route-${employeeAll[i].tag_serial}`)) {
              mapRef.current.removeLayer(`route-${employeeAll[i].tag_serial}`);
              mapRef.current.removeSource(`route-${employeeAll[i].tag_serial}`);
            }
          const sourceId = `routematch${employeeAll[i].tag_serial}`;
            if (mapRef.current.getSource(sourceId)) {
              mapRef.current.removeLayer(sourceId);
              mapRef.current.removeSource(sourceId);
            }
        }


      updateRoute(dataToShow.map(el=>el.location),dataToShow,timeData,selectedEmployee)  
    
  }
}, [dualLocationList,selectedEmployee, userMovement,currentCount, loader,defaultPoly,employeeAll]);





  function formatAMPMHour(date) {
    var hours = date;
    var minutes = 0;
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function onChangeStartTime(val){

    let dept= shiftBasedDropdown;
  
    if(val.indexOf('all')> -1){
        let value =dept;
        if((val.length -1) == value.length ){
          SetStartTime([])
        }else{
          SetStartTime(value);
        }
    }else{
      SetStartTime(val);
    }
  }

      function changeEmployeeList(start,selectedDate,slug){
      let requestBody={};
      console.log("start,selectedDate,slug",start,selectedDate,slug)
      requestBody.hour= moment(start).format('HH')+":00:00";
      requestBody.date=getDateFormat(start);
      requestBody.slug=slug=='employee'?'regular':'daily';
      
      SetDepartmentID([]);
      SetSubDepartmentID([]);
      SetEmployeeDefault([]);
      SetEmployeeAll([]);
      
      getActiveEmployee(requestBody,userSession,org_id,sub_org_id).then(res=>{
        if(res.status==200){
          let cat=[];
          
          for(let i=0;i<res.data.length;i++){
            let index= cat.findIndex(item=>item.department == res.data[i].department_name )
            if( index > -1){
              cat[index].sub_department.push(res.data[i].sub_department_name);
            }else{
              cat.push({department:res.data[i].department_name,sub_department:[res.data[i].sub_department_name] })              
            }

          }
          SetDeparment(cat);
          SetEmployeeDefault(res.data);
          SetEmployeeAll(res.data);          
        }
      })
    }

    function changeDepartment(val){
      let dept= department;
      SetEmployeeID([]);
    
      if(val.indexOf('all')> -1){
          let value =[];
          for(let i=0;i<dept.length;i++){
            value.push(dept[i].department)
          }
          if((val.length -1) == value.length ){
            SetDepartmentID([]);  
            SetEmployeeAll([]);
          }else{

            let empList2 = employeeDefault.filter(item=>value.indexOf(item.department_name) > -1);
          
            SetEmployeeAll(empList2);
            SetDepartmentID(value);
          }
    
          
      }else{
        let empList2 = employeeDefault.filter(item=>val.indexOf(item.department_name) > -1);
    
        SetEmployeeAll(empList2);
        SetDepartmentID(val);
      }
    }

        function changeSubDepartment(val){
      let dept= sub_department;
      let actualSub=[];
      if(val.indexOf('all')> -1){
          let value =[];
          for(let i=0;i<dept.length;i++){
            value.push(dept[i])
          }
          if((val.length -1) == value.length ){
            SetSubDepartmentID([]);
          }else{
            actualSub=value;
             SetSubDepartmentID(value);
          }   
          
      }else{
        let emp=[]
        let sub=[...val];
        SetSubDepartmentID(sub);
        actualSub=val;   
      }
      let empList=employeeDefault.filter(item=>actualSub.indexOf(item.sub_department_name)> -1);
      SetEmployeeAll(empList);
    }

  function onChangeEmployee(val){
    let dept= employeeAll;
      if(val.indexOf('all')> -1){
          let value =[];
          for(let i=0;i<dept.length;i++){
            value.push(dept[i].worker_id)
          }
          if((val.length -1) == value.length ){
            SetEmployeeID([]);  
            SetSelectedEmployee([])
            console.log("dept1 everything empty");
          }else{
            SetEmployeeID(value);
            
            let allTag = value.map(el=>{
              let tag= employeeAll.find(item=>item.worker_id == el)?.tag_serial
              return tag;
            })
            console.log("dept else 2",dept,value,allTag);
            SetTagSerial(allTag)
            SetSelectedEmployee([])
          }
          
      }else{
        let allTag = val.map(el=>{
              let tag= employeeAll.find(item=>item.worker_id == el)?.tag_serial
              return tag;
            })
        SetEmployeeID(val);
        SetTagSerial(allTag)
        console.log("dept 3",dept,val,allTag);

      }
      
    }
    function onChangeEmployee2(val){
      let dept= employeeID.map((item,i)=>{                   
        if(dualLocationList.filter(item=>item.employee_tag==employeeID[i]).length > 0)
          return item;
      }) 

        if(val.indexOf('all')> -1){
            let value =[];
            for(let i=0;i<dept.length;i++){
              value.push(dept[i])
            }
            if((val.length -1) == value.length ){
              SetSelectedEmployee([]);  
            }else{
              SetSelectedEmployee(value);
            }
      
            
        }else{
          SetSelectedEmployee(val);
        }
      }

  return (<>
    {permissionAPICall?permission.length > 0? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
        <Col lg={6}>
          
          <div className='tabHeader'>
            
              {props.pages.filter(el=> el && el.sub_page_name =="Employee Tracker Mapbox" ).length >0?<div className='tabHeaderList ' onClick={()=>props.history.push('/mapbox-employee-tracker')} >
              <CommonHeading title="Employee Tracker"/>
              </div>:""}   
              {props.pages.filter(el=> el && el.sub_page_name =="Facility Tracker Mapbox" ).length >0?<div className='tabHeaderList ' onClick={()=>props.history.push('/geo-map-outdoor')}>
              <CommonHeading title="Facility Tracker" />
              </div>:""}
              {props.pages.filter(el=> el && el.sub_page_name =="Movement Analyzer Mapbox" ).length >0?<div className='tabHeaderList active'>
                  <CommonHeading title="Movement Analyzer" />
              </div>:""}
           </div>   
        </Col>
        <Col lg={6} className="text-right">
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="commonHeadingDateMainDivParent">
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
            />
          </div>
        </Col>
      </Row>

      <Row className="m-t" >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
          <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}> Filter</h5>
            </div>
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Selct User Type"
                optionFilterProp="children"
                onChange={(val)=>{SetSlug(val); }}
                //defaultValue={'Select Department'}
                value={slug==''?'Select User Type':slug}
                key="departmentUserType"
                style={{width:140}}
                // disabled={!space || !lagFreeFlag}
                
            >
              <Option value='employee' key={"UserTypeRegular All"}>Regular Worker</Option>
              <Option value='daily_worker' key={"UserTypeDaily All"}>Daily Worker</Option>
              
            </Select>            
          </div>
          <div className='inlineBlockFilter inlineBlockFilterRearrange'>
                  <label style={{width:70}}>Hours</label> <span style={{float:"right"}}></span>
                <Select
                    placeholder="Select Start Time"
                    optionFilterProp="children"
                    style={{width:130}}
                    onChange={(val)=>{console.log(val);SetStartTime(val);changeEmployeeList(val,selectedDate,slug); }}
                    
                    value={start_time}
                    disabled={slug==''}
                    key="StartTimeV1"
                    dropdownClassName={'smallerDrop'}
                    listHeight={400}

                >
                    {
                    time.map((item,index)=>{

                      
                        return <Option value={moment(item.currentTime).format('YYYY-MM-DD HH:mm:ss')} disabled={moment(item.currentTime)> moment()} key={"Employee V1 TIme"+item.time+index}>{ item.time}</Option>
                    })
                    }
      
                </Select>
            </div>


            

            <div className='inlineBlockFilter inlineBlockFilterRearrange'>
              <Select
                placeholder="Select Department"
                onChange={(val)=>{changeDepartment(val); }}
                value={department_id}
                key="departmentFilterv1"
                style={{width:200}}
                dropdownClassName={'smallerDrop'}
                disabled={slug==''|| start_time.length== 0?true:false}
                 mode="multiple"
            >
             <Option value='all' key={"Department v1 All"}>Select All</Option>
                {
                department && department.length > 0? department.map((item,index)=>{
                    return <Option value={item.department} key={"Department V1"+item.department+index}>{item.department}</Option>
                }):""
                }
            </Select>
                </div>
                <div className='inlineBlockFilter inlineBlockFilterRearrange'>
                <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:200}}
                    onChange={(val)=>{changeSubDepartment(val); }}
                    disabled={department_id=='' || start_time.length==0?true:false}                   
                    value={sub_department_id}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}
                    mode="multiple"
                >
                  <Option value='all' key={"Sub Department v1 All"}>Select All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item,index)=>{
                        return <Option value={item} key={"Sub Department V1"+item+index}>{item}</Option>
                    }):""
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter inlineBlockFilterRearrange'>
                <Select
                  placeholder="Select Employee"
                  optionFilterProp="children"
                  style={{width:150}}
                onChange={(val)=>{onChangeEmployee(val); }  }
                disabled={department_id.length==0 || sub_department_id.length==0 || start_time.length==0?true:false} 
                //defaultValue={'Select Department'}
                value={employeeID}
                key="employeeFIlterv1"
                 mode="multiple"
                dropdownClassName={'smallerDrop'}
  
            >
            <Option value='all' key={"Select EMployee v2 All"}>Select All</Option>                  
              {
                    employeeAll && employeeAll.length > 0? employeeAll.map((item,index)=>{
                        return <Option value={item.worker_id} key={"Employee V2"+item.name+index+Math.random(0,1)}>{item.name}</Option>
                    }):""
                    }
                    
                </Select>



                </div>
                <div className='inlineBlockFilter'>
                  <div className="btndiv">
                      <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()}
                      //  disabled={ department_id.length==0  || start_time.toString()==''  || employeeID.length ==0}
                       >Submit</button>
                      
                  </div>                
                </div>


</div>
</Col>
    </Row>






      <Row className="m-t">
        <Col lg={9} className='tabletfull' ref={elementRef}>
 
 
                    {loaderEmp? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4> 
                    
                    

                  </div></div>:''}
                  
                  {sucessMessage && defaultPoly.length >0?<div className='loaderStyle loadStyleSucess' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Ready

                                                     <div className='inlineBlockFilterRight'><Select
                  placeholder="Select Employee"
                  optionFilterProp="children"
                  style={{width:150}}
                onChange={(val)=>{onChangeEmployee2(val) }  }
                // showArrow={true}
                showSearch={false}
                //defaultValue={'Select Department'}
                value={selectedEmployee}
                key="employeeFIlterv2"
                 mode="multiple"
                dropdownClassName={'smallerDrop'}
  
            >
            <Option value='all' key={"Select EMployee v2 All"}>Select All</Option>                  
              {
                    employeeID && employeeID.length > 0? employeeID.map((item,i)=>{
                     
                      
                      if(dualLocationList.filter(item=>item.worker_id==employeeID[i]).length > 0)
                        return <Option value={employeeDefault.find(e=>e.worker_id==item)?.tag_serial || item } key={"Employee V2 ID"+item+i}>{employeeDefault.find(e=>e.worker_id==item)?.name }</Option>
                    }):""
                    }
                    
                </Select>    </div>
                    </h4> 

                  </div></div>:sucessMessage && defaultPoly.length ==0?<div className='loaderStyle loadStyleError' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Oops! This Hour data not available</h4> 
                  </div></div>:""}
                  {isloading? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:<>
                                            <div id="container" style={{ display: 'flex', height: "calc(100vh - 170px)", position: "relative" }}>
                <div ref={mapContainerRef} style={{ width: '100%' }} />
                {!loader ? <div className='absGreatStyle' style={{ width: widthBox }}>
                    <div onClick={() => SetWidthBox((prev) => (prev === 60 ? 480 : 60))} className='currentStyle boxStyle'>
                        <img src={currentModeImg} alt="Mode Icon" />
                        <small>{currentMode}</small>
                    </div>
                    <div className='expandMode'>
                        {mapBoxMode.map(el => (
                            <div key={el.title} className={el.title === currentMode ? ' boxStyle active' : 'boxStyle'}
                                onClick={() => changeMapStyle(el.function, el.title, el.image)}>
                                <img src={el.image} alt="Map Style Icon" />
                                <small>{el.title}</small>
                            </div>
                        ))}
                    </div>
                </div> : ""}
            </div>
                                          <div position='center' my='md'>
        
       
        <Row classNam
        e='nicerFormatRange'>
          <Col lg={12}>
            <Slider
              min={0} 
              max={defaultPoly.length==0?120:defaultPoly.length}
              value={currentCount}
              onChange={(e)=>HandleRange(e,defaultPoly)}
              // tooltipVisible={false}
              tooltipVisible={currentCount==0 || defaultPoly.length == 0?false:true}
        tipProps={{ placement: 'top', visible: true }}
         tipFormatter={(index) => convertTimeZone(filterTimeData[currentCount - 1]?.time)}
            />
            {/* { defaultPoly.length > 0 && marks[currentCount]?<div className='sliderLeftTooltip'>
                <div className='tooltipCustom' style={{left:( ((currentCount * width)/ 120) *100)/(width)+"%"}}>
                  <span className='tooltipArea' style={{float:"right"}}>
                   {marks[currentCount - 1]}
                  </span>
                </div>

            </div>:""} */}

          </Col>
          <Col lg={2}>
          
          </Col>

        </Row>
          
      </div>                                
                                    </>}


                  </Col>
          <Col lg={3} className="p-l-0 tabletfull">
          <div className="legendArea manStyle legendArea2" style={{height:heightMap + 50,background:"#fff",minHeight:"100%",marginBottom:30}}>
            {filterTimeData.length>0?<div className='legendSmallDate'>Date <strong>{startBigger ===true  && start_time < 11 ?moment(selectedDate, "YYYY-MM-DD").add(1, 'days').format("MMM DD"):moment(selectedDate).format("MMM DD")}</strong> ( { formatAMPMHour(start_time)})</div>:""}
            <div className='legendStart'>
            {/* <div className='legendSwitch'>
                
                <Switch defaultChecked={enable} onChange={onChangeEnable} />
                <span className='title'>{enable?"Location Log":"All Log"}</span>
              </div> */}
              <div className={enable?'legendList legendHeader  three-colsHeader':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  {enable?<div className='legendLeftAreaTime'><strong>Spend</strong></div>:""}
                  <div className='legendRightAreaProd'><strong>Time</strong></div>
              </div>

  
                    <Scrollbars style={{height:"calc(-190px + 100vh)"}}>{filterTimeData.map((item,index)=>{

                      return(
                                <div key={"tracker"+item.time+index} className={enable?'legendList three-colsHeader':'legendList'}>
                                        <div className='legendLeftAreaProd'>{item.name}<span className='empName'>{item.emp_name}</span></div>
                                        {enable?<div className='legendLeftAreaTime'>{moment.utc(item.time_spent).format("HH:mm:ss")}</div>:""}
                                        <div className='legendRightAreaProd'>{convertTimeZone(item.time)}</div>
                                </div>
                                )
                    })}</Scrollbars>
                
                </div>

          </div>
        </Col>        

      

      </Row>
    </Container>
  </div>:
<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>

  )
}

export default MapboxMovementTracker