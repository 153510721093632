import axios from "axios";
let prefixURL = process.env.REACT_APP_ADMIN_V2
let prefixURL2 = process.env.REACT_APP_URL_LOGIN;
let prefixURLAPP2 =process.env.REACT_APP_URL_V2
let prefixURLV1 = process.env.REACT_APP_URL
let token = 'ezrsrdfghawtsetrrAZSXCxfa'
export function CreteGeoMap(requestBody) {
    
    return axios.post(prefixURL + `/location/create_geo_map` , requestBody).then(res=>res.data).catch(err=>err)
}

export function getLocationCordinate(requestBody) {
    return axios.get(prefixURL + `/location/get_location_cordinate?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
}
export function getLocationCordinateOld(requestBody) {
    return axios.get(prefixURL2 + `/location/get_location_cordinate?session=${requestBody.session}&org_id=${requestBody.org_id}`).then(res=>res.data).catch(err=>err)
}

export function CreteGeoMapLocation(requestBody) {    
    return axios.post(prefixURL + `/location/create_geo_map_location` , requestBody).then(res=>res.data).catch(err=>err)
}

export function removeMapLocation(requestBody){
    return axios.post(prefixURL + `/location/remove_geo_map_location` , requestBody).then(res=>res.data).catch(err=>err)
}

export function getLocationList(requestBody){
    return axios.get(prefixURL2 + `/location/get_locations?org_id=${requestBody.org_id}&session=${requestBody.session}` , ).then(res=>res.data).catch(err=>err)
}
export function getEmployeeTracker(requestBody , sessionID, id) {
    return axios.get(prefixURLAPP2 + `/get_employee_tracker?session=${sessionID}&date=${requestBody.date}&org_id=${id}&start_time=${requestBody.start_time}&end_time=${requestBody.end_time}&worker_id=${requestBody.worker_id}&work_day=${requestBody.work_day}`)
        .then(res => res.data).catch(err => err)
}